import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('dashboard', ['profile']),
  },
  mounted() {
    this.getLimitOrders()
  },
  methods: {
    newTab(url) {
      window.open(url, '_blank')
    },
    async getLimitOrders(value) {
      await this.$http_komship.get('/v1/user/limit-order')
        .then(response => {
          const { data } = response.data
          if (!data.is_lock) {
            if (value === 'click') {
              this.$router.push({ path: '/add-order' })
            } else {
              this.$bvModal.hide('modal-validation-add-order')
            }
          } else {
            this.$bvModal.show('modal-validation-add-order')
          }
        })
    },
  },
}
